import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const usePricingData = () => {
  return useStaticQuery(graphql`
    {
      prismicPricingv2 {
        data {
          body {
            ... on PrismicPricingv2DataBodyGeneralCard {
              primary {
                description
                title
              }
            }
          }
          checkimage {
            alt
            gatsbyImageData
            url
          }
          cta_background_image {
            alt
            gatsbyImageData
            url
          }
          cta_description {
            richText
            text
          }
          cta_title
          faq_questions {
            answer
            question
          }
          faq_title
          integrations {
            url {
              url
            }
            name
            mobilename
            integration_images {
              alt
              url
              gatsbyImageData
            }
          }
          livedemoimage {
            alt
            gatsbyImageData
            url
          }
          mobile_cta_description {
            richText
            text
          }
          pricing_card {
            desc
            featureheading
            features {
              richText
              text
            }
            plan_name
            price
            price_subtext
            slack {
              alt
              gatsbyImageData
              url
            }
            teams {
              alt
              gatsbyImageData
              url
            }
          }
          slackimage {
            alt
            gatsbyImageData
            url
          }
          teamsimage {
            alt
            gatsbyImageData
            url
          }
          title {
            text
          }
        }
      }
    }
  `)?.prismicPricingv2?.data
}

export default usePricingData

import React from "react"
import "./Darkbutton.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

interface DarkButtonProps {
  title?: string
  alt?: any
  gatsbyImage?: any
  link?: string
  width?: string
}

const DarkButtonLink: React.FC<DarkButtonProps> = ({
  title,
  alt,
  gatsbyImage,
  link,
  width,
}) => {
  return (
    <Link target="_blank" rel="noopener noreferrer" to={link ?? ""}>
      <button
        style={{
          width: width,
        }}
        className="DarkButtonLink"
      >
        <GatsbyImage alt={alt} image={gatsbyImage} />
        {title}
      </button>
    </Link>
  )
}

export default DarkButtonLink

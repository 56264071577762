import React from "react"
import "./Pricing.scss"
import usePricingData from "hooks/usePricingData"
import { Layout, SEO } from "components"
import { GatsbyImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import DarkButtonLink from "components/DarkButton/DarkButton"
import BottomCtaSection from "components/BottomCtaSection/BottomCtaSection"
const Pricing = () => {
  const data = usePricingData()
  const {
    checkimage = null,
    cta_background_image = null,
    cta_description = null,
    cta_title = null,
    faq_questions = [],
    faq_title = null,
    integrations = [],
    livedemoimage = null,
    mobile_cta_description = null,
    pricing_card = [],
    slackimage = null,
    teamsimage = null,
    title = null,
  } = data

  return (
    <Layout>
      <SEO {...data?.body[0]?.primary} />
      <div className="pricing-v2">
        <div className="Price-section">
          <div className="title">{title.text}</div>
          <div className="pricing-container">
            {pricing_card?.map((item: any, index: number) => (
              <div className="card">
                <div className="plan-name">
                  <div className="plan-title">{item?.plan_name}</div>
                  <div className="plan-label">{item?.desc}</div>
                </div>
                <div className="price">
                  <div className="price-name">
                    {item?.price}

                    <div className="subtext">{item?.price_subtext}</div>
                  </div>
                  <div className="images">
                    <GatsbyImage
                      image={item?.slack?.gatsbyImageData}
                      alt={item?.slack?.alt}
                    />
                    <GatsbyImage
                      image={item?.teams?.gatsbyImageData}
                      alt={item?.teams?.alt}
                    />
                  </div>
                </div>
                <div className="heading">{item?.featureheading}</div>
                <PrismicRichText
                  field={item?.features?.richText}
                  components={{
                    heading5: ({ children }) => (
                      <div className="feature-heading">{children}</div>
                    ),
                    list: ({ children }) => (
                      <div className="feature-list">{children}</div>
                    ),
                    listItem: ({ children }) => (
                      <div className="feature-item">
                        <div className="text">{children}</div>
                        <div className="image">
                          <GatsbyImage
                            alt={checkimage?.alt}
                            image={checkimage?.gatsbyImageData}
                            objectFit="contain"
                          />
                        </div>
                      </div>
                    ),
                  }}
                />
                <div className="button-container">
                  {item?.price?.trim()?.toLowerCase() !==
                  "Custom"?.toLowerCase() ? (
                    <>
                      <DarkButtonLink
                        title="Add to Slack"
                        width="100%"
                        alt={slackimage?.alt}
                        gatsbyImage={slackimage?.gatsbyImageData}
                        link="/add-to-slack"
                      />
                      <DarkButtonLink
                        title="Add to Teams"
                        width="100%"
                        alt={teamsimage?.alt}
                        gatsbyImage={teamsimage?.gatsbyImageData}
                        link={"/add-to-teams"}
                      />
                    </>
                  ) : (
                    <DarkButtonLink
                      title="Request live Demo"
                      width="100%"
                      alt={livedemoimage?.alt}
                      gatsbyImage={livedemoimage?.gatsbyImageData}
                      link="https://calendly.com/teamble/30min"
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="faq-section">
          <div className="faq-title">{faq_title}</div>
          <div className="faq-list">
            {faq_questions?.map((item: any, index: number) => (
              <div className="faq-item">
                <div className="heading">{item?.question}</div>
                <div className="answer">{item?.answer}</div>
              </div>
            ))}
          </div>
        </div>
        <BottomCtaSection
          title={cta_title}
          desc={cta_description}
          backgroundimage={cta_background_image}
          integration={integrations}
          mobileText={mobile_cta_description}
        />
      </div>
    </Layout>
  )
}
export default Pricing
